import request from "utils/request";
import qs from "qs";

// 发送验证码
export function sendSmsCode(query) {
  return request({
    url: "/wap-api/hwy/sendSmsCode",
    method: "get",
    params: query
  });
}
// 绑定手机号
export function bindMobile(query) {
  return request({
    url: "/wap-api/auth/bindMobile",
    method: "get",
    params: query
  });
}
// token过期/失效/或者其他情况根据openid重新获取
export function authOpenId(query) {
  return request({
    url: "/wap-api/auth/authOpenId?openId=" + localStorage.getItem("openId"),
    method: "get",
    params: query
  });
}
// 首页banner
export function getLsHomeBannerListOut(data) {
  return request({
    url: "/wap-api/home/getLsHomeBannerListOut",
    method: "post",
    data: qs.stringify(data)
  });
}
// 首页技师推荐
export function getLsHomeArtificerListOut(query) {
  return request({
    url: "/wap-api/home/getLsHomeArtificerListOut",
    method: "get",
    params: query
  });
}
// 首页服务项目
export function getLsHomeServeListOut(query) {
  return request({
    url: "/wap-api/home/getLsHomeServeListOut",
    method: "get",
    params: query
  });
}
// 服务详情
export function getLsServeDetailOut(query) {
  return request({
    url: "/wap-api/serve/getLsServeDetailOut",
    method: "get",
    params: query
  });
}
// 推广渠道
export function editLsPromotionChannelEnterData(query) {
  return request({
    url: "/wap-api/promotion/channel/editLsPromotionChannelEnterData",
    method: "get",
    params: query
  });
}
// 通过code获取微信用户信息
export function addWxUserDetail(query) {
  return request({
    url: "/wap-api/wx/gzh/addWxUserDetail",
    method: "get",
    params: query
  });
}
// 获取微信授权
export function getLsWxSignatureOut(query) {
  let url = window.location.href;
  return request({
    url: "/wap-api/wx/gzh/getLsWxSignatureOut",
    method: "get",
    params: {
      url: url
    }
  });
}

// 定位成功获取cityCode
export function getCityCode(query) {
  return request({
    url: "/wap-api/district/getCityCode",
    method: "get",
    params: query
  });
}
// 领券中心列表
export function getLsCouponByTypePage(data) {
  return request({
    url: "/wap-api/coupon/getLsCouponByTypePage",
    method: "post",
    hds: 2,
    data: data
  });
}
// 领取优惠券
export function addMemberCoupon(query) {
  return request({
    url: "/wap-api/coupon/addMemberCoupon",
    method: "get",
    params: query
  });
}
// 文件上传
export function uploadFiles(data) {
  return request({
    url: "/wap-api/hwy/upload",
    method: "post",
    hds: 1,
    data: data
  });
}

// 首页会员中心
export function homeVipCenter() {
  return request({
    url: "/wap-api/vip/getLsVipInfo",
    method: "get"
  });
}

//  招聘合作
export function recruit(data) {
  return request({
    url: "/wap-api/home/artificerRecruitment",
    method: "post",
    hds: 2,
    data: data
  });
}

// 购买VIP
export function payVip(query) {
  return request({
    url: "/wap-api/vip/buyLsVip",
    method: "get",
    params: query
  });
}
