import request from "utils/request";
import qs from "qs";

// 获取个人信息
export function getLsMyProfile(query) {
  return request({
    url: "/wap-api/my/getLsMyProfile",
    method: "get",
    params: query
  });
}

// 获取我的优惠券
export function getLsMyCouponByPage(data) {
  return request({
    url: "/wap-api/my/getLsMyCouponByPage",
    method: "post",
    hds: 2,
    data: data
  });
}
// 修改个人信息
export function editProfile(data) {
  return request({
    url: "/wap-api/my/editProfile",
    method: "post",
    hds: 2,
    data: data
  });
}
// 我的收藏
export function pageCollectArtificer(data) {
  return request({
    url: "/wap-api/my/pageCollectArtificer",
    method: "post",
    hds: 2,
    data: data
  });
}
// 我的服务地址
export function pageServeAddress(data) {
  return request({
    url: "/wap-api/my/pageServeAddress",
    method: "post",
    hds: 2,
    data: data
  });
}
// 获取服务地址详情
export function getServeAddressDetail(query) {
  return request({
    url: "/wap-api/my/getServeAddressDetail",
    method: "get",
    params: query
  });
}
// 修改服务地址
export function editServeAddress(data) {
  return request({
    url: "/wap-api/my/editServeAddress",
    method: "post",
    hds: 2,
    data: data
  });
}
// 删除服务地址
export function deleteServeAddress(query) {
  return request({
    url: "/wap-api/my/deleteServeAddress",
    method: "get",
    params: query
  });
}
// 取消收藏
export function noCollectionArtificer(query) {
  return request({
    url: "/wap-api/my/noCollectionArtificer",
    method: "get",
    params: query
  });
}
// 用户反馈
export function addFeedBack(data) {
  return request({
    url: "/wap-api/my/addFeedBack",
    method: "post",
    hds: 2,
    data: data
  });
}
// 我的评价列表
export function pageMyComment(data) {
  return request({
    url: "/wap-api/my/pageMyComment",
    method: "post",
    hds: 2,
    data: data
  });
}
// 删除评论
export function deleteMyComment(query) {
  return request({
    url: "/wap-api/my/deleteMyComment",
    method: "get",
    params: query
  });
}
// 我的--为您推荐
export function pageUserRecommendedArtificer(data) {
  return request({
    url: "/wap-api/my/pageUserRecommendedArtificer",
    method: "post",
    hds: 2,
    data: data
  });
}

// 创建地址
export function addAddress(data) {
  return request({
    url: "/wap-api/my/addServeAddress",
    method: "post",
    hds: 2,
    data: data
  });
}

// 我的服务地址
export function myAddress(data) {
  return request({
    url: "/wap-api/my/pageServeAddress",
    method: "post",
    hds: 2,
    data: data
  });
}

// 关注
export function follow(query) {
  return request({
    url: "/wap-api/my/collectionArtificer",
    method: "get",
    params: query
  });
}

// 获取邀请码
export function getInviteCode(query) {
  return request({  
    url: '/wap-api/member/invite/getInviteCode',
    method: 'get',
    params:query
  })
}