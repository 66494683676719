import wx from "weixin-js-sdk";
import { getLsWxSignatureOut } from "@/api/index";
export function getweekday(date) {
  var weekArray = new Array(
    "周日",
    "周一",
    "周二",
    "周三",
    "周四",
    "周五",
    "周六"
  );
  var week = weekArray[new Date(date).getDay()];
  return week;
}

export const fillZero = n => {
  const result = n.toString().length == 1 ? "0" + n : n;
  return result;
};

export const formatTime = (t = new Date(), type) => {
  const d = new Date(t);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  const h = d.getHours();
  const m = d.getMinutes();
  const s = d.getSeconds();
  var result = "";
  if (type == 1) {
    result = `${year}-${fillZero(month)}-${fillZero(date)} ${fillZero(
      h
    )}:${fillZero(m)}:${fillZero(s)}`;
  } else if (type == 2) {
    result = `${year}-${fillZero(month)}`;
  } else if (type == 3) {
    result = {
      year,
      month: fillZero(month),
      date: fillZero(date),
      h: fillZero(h),
      m: fillZero(m),
      s: fillZero(s)
    };
  } else {
    result = `${year}-${fillZero(month)}-${fillZero(date)}`;
  }
  return result;
};

export const getToDay = str => {
  let d = new Date(str).setHours(0, 0, 0, 0);
  let today = new Date().setHours(0, 0, 0, 0);
  let obj = {
    0: "今",
    86400000: "明",
    172800000: "后"
  };
  return obj[d - today];
};

// 验证手机号
export const isValidPhoneNumber = phoneNumber => {
  return /^1[3-9]\d{9}$/.test(phoneNumber);
};

// 判断对象key是否为空
export const hasEmptyValues = obj => {
  return Object.keys(obj).some(key => obj[key] === "");
};

// 节流
export const throttle = (func, limit) => {
  let inThrottle;
  return function() {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

import {getInviteCode} from '@/api/my'
export const wxConfig = (shareData) => {
  return new Promise(function(resolve, reject) {
    getLsWxSignatureOut().then(res => {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: "wx29a07078b3670b13", // 必填，公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.noncestr, // 必填，生成签名的随机串
        signature: res.data.signature, // 必填，签名
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "hideAllNonBaseMenuItem",
          "showAllNonBaseMenuItem",
          "getLocation",
          "showMenuItems"
        ] // 必填，需要使用的JS接口列表
      });
      wx.ready(function() {
        if(localStorage.getItem("authorization") && !localStorage.getItem("shareCode")){
          getInviteCode().then(res=>{
            localStorage.setItem("shareCode",res.data)
          })
        }
        let shareCode = localStorage.getItem("shareCode")
        // 默认分享系统收首页 shareData 指定的分享页面
        var shareDta = {
          title: "蓝瑟到家，累了点TA",
          desc: "蓝瑟到家，正规绿色大平台，您的专属理疗师",
          link:shareCode?window.location.origin + `/#/?shareCode=${shareCode}`:window.location.origin,
          imgUrl: "https://lsdj-img.lanshididu.com/wx/ffc1c97d4c33d32af0fe257b77b7985.png",
        }
        if(shareData){
          shareDta = shareData
        }
        console.log("shareDta",shareDta)
        // 分享给朋友
        wx.updateAppMessageShareData({
          title: shareDta.title, // 分享标题
          desc: shareDta.desc, // 分享描述
          link: shareDta.link,
          imgUrl: shareDta.imgUrl, // 分享图标
          success: function () {
            // 设置成功
          }
        })
        // 分享到朋友圈
        wx.updateTimelineShareData({
          title: shareDta.title, // 分享标题
          link: shareDta.link,
          imgUrl: shareDta.imgUrl, // 分享图标
          success: function () {
            // 设置成功
          }
        })
        resolve(wx);
      });
      wx.error(function(res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        console.log("微信信息验证失败", res);
      });
    });
  });
};
