<template>
  <div class="loading-div">
    <van-loading vertical size="30px" >加载中...</van-loading>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.loading-div {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.8);
}
</style>
