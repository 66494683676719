import Vue from "vue";
import VueRouter from "vue-router";
import { wxConfig } from "@/utils/index";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "home",
    component: resolve => require(["views/home.vue"], resolve),
    meta: {
      title: "首页"
    }
  },
  {
    path: "/selectAddress",
    name: "selectAddress",
    component: resolve => require(["views/home/selectAddress.vue"], resolve),
    meta: {
      title: "请选择服务地址"
    }
  },
  {
    path: "/cityList",
    name: "cityList",
    component: resolve => require(["views/home/cityList.vue"], resolve),
    meta: {
      title: "选择城市"
    }
  },
  {
    path: "/addAddress",
    name: "addAddress",
    component: resolve => require(["views/home/addAddress.vue"], resolve),
    meta: {
      title: "编辑服务地址",
      keepAlive: true
    }
  },
  {
    path: "/amap",
    name: "amap",
    component: resolve => require(["views/home/amapLocation.vue"], resolve),
    meta: {
      title: "选择地址",
      // keepAlive:true
    }
  },
  {
    path: "/service",
    name: "service",
    component: resolve => require(["views/service.vue"], resolve),
    meta: {
      title: "服务者",
      // keepAlive:true
    }
  },
  {
    path: "/order",
    name: "order",
    component: resolve => require(["views/order.vue"], resolve),
    meta: {
      title: "订单"
    }
  },
  {
    path: "/my",
    name: "my",
    component: resolve => require(["views/my.vue"], resolve),
    meta: {
      title: "我的"
    }
  },
  {
    path: "/first-order-benefits",
    name: "first-order-benefits",
    component: resolve =>
      require(["views/home/first-order-benefits.vue"], resolve),
    meta: {
      title: "领券中心"
    }
  },
  {
    path: "/vipCenter",
    name: "vipCenter",
    component: resolve => require(["views/service/vipCenter.vue"], resolve),
    meta: {
      title: "会员中心"
    }
  },
  {
    path: "/recruit",
    name: "recruit",
    component: resolve => require(["views/service/recruit.vue"], resolve),
    meta: {
      title: "服务者招聘",
      keepAlive: true
    }
  },
  {
    path: "/projectDetails",
    name: "projectDetails",
    component: resolve =>
      require(["views/service/projectDetails.vue"], resolve),
    meta: {
      title: "服务详情",
      keepAlive: true
    }
  },
  {
    path: "/attendantDetails",
    name: "attendantDetails",
    component: resolve =>
      require(["views/service/attendantDetails.vue"], resolve),
    meta: {
      title: "服务者详情"
    }
  },
  {
    path: "/selectAttendant",
    name: "selectAttendant",
    component: resolve =>
      require(["views/service/selectAttendant.vue"], resolve),
    meta: {
      title: "选择服务者"
    }
  },
  {
    path: "/searchAttendant",
    name: "searchAttendant",
    component: resolve =>
      require(["views/service/searchAttendant.vue"], resolve),
    meta: {
      title: "搜索服务者"
    }
  },
  {
    path: "/recruit-new",
    name: "recruit-new",
    component: resolve => require(["views/service/recruit-new.vue"], resolve),
    meta: {
      title: "拉新赚钱"
    }
  },
  {
    path: "/my/info",
    name: "my-info",
    component: resolve => require(["views/my/info.vue"], resolve),
    meta: {
      title: "个人信息"
    }
  },
  {
    path: "/my/collect",
    name: "my-collect",
    component: resolve => require(["views/my/collect.vue"], resolve),
    meta: {
      title: "我的收藏"
    }
  },
  {
    path: "/my/coupon",
    name: "my-coupon",
    component: resolve => require(["views/my/coupon.vue"], resolve),
    meta: {
      title: "我的优惠券"
    }
  },
  {
    path: "/my/service-address",
    name: "my-service-address",
    component: resolve => require(["views/my/service-address.vue"], resolve),
    meta: {
      title: "服务地址"
    }
  },
  {
    path: "/my/feedback",
    name: "my-feedback",
    component: resolve => require(["views/my/feedback.vue"], resolve),
    meta: {
      title: "反馈"
    }
  },
  {
    path: "/my/evaluate",
    name: "my-evaluate",
    component: resolve => require(["views/my/evaluate.vue"], resolve),
    meta: {
      title: "我的评价"
    }
  },
  {
    path: "/order/confirmOrder",
    name: "order-confirmOrder",
    component: resolve => require(["views/order/confirmOrder.vue"], resolve),
    meta: {
      title: "预约下单",
      keepAlive: true
    }
  },
  {
    path: "/order/details",
    name: "order-details",
    component: resolve => require(["views/order/details.vue"], resolve),
    meta: {
      title: "订单详情"
    }
  },
  {
    path: "/order/evaluate",
    name: "order-evaluate",
    component: resolve => require(["views/order/evaluate.vue"], resolve),
    meta: {
      title: "评价"
    }
  },
  {
    path: "/order/orderClock",
    name: "order-orderClock",
    component: resolve => require(["views/order/orderClock.vue"], resolve),
    meta: {
      title: "服务加钟"
    }
  },
   {
    path: "/serverMap",
    name: "serverMap",
    component: resolve => require(["views/service/serverMap.vue"], resolve),
    meta: {
      title: "服务者位置",
    }
  },
  {
    path: "/download3",
    name: "download3",
    component: resolve => require(["views/download/download3.vue"], resolve),
    meta: {
      title: "下载",
    }
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: "/",
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { x: 0, y: 0 };
  }
});
router.afterEach((to, from) => {
  wxConfig().then(wx => {
    if(to.path !== "/recruit-new" && to.path !== '/' && to.path !== '/attendantDetails' && to.path !=='/projectDetails'){
        wx.hideAllNonBaseMenuItem();
    }else{
        wx.showAllNonBaseMenuItem();
    }
    if(to.path ==='/download3'){
        wx.showMenuItems({
          menuList: ["menuItem:copyUrl","menuItem:openWithQQBrowser","menuItem:openWithSafari"] // 要显示的菜单项，所有menu项见附录3
        });
    }
  })
});
export default router;
