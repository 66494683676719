<template>
  <div>
    <van-tabbar v-model="$store.state.tabBarActive" inactive-color="#999" active-color="#ee0a24">
      <van-tabbar-item @click="goPage('/')">
        <template #icon="props">
          <svg-icon
            :icon-class="props.active ? 'house_select' : 'house'"
          ></svg-icon>
        </template>
        <span>首页</span>
      </van-tabbar-item>
      <van-tabbar-item @click="goPage('/service')">
        <template #icon="props">
          <svg-icon
            :icon-class="props.active ? 'person_select' : 'person'"
          ></svg-icon>
        </template>
        <span>服务者</span>
      </van-tabbar-item>
      <van-tabbar-item @click="goPage('/order')">
        <template #icon="props">
          <svg-icon
            :icon-class="props.active ? 'order_select' : 'order'"
          ></svg-icon>
        </template>
        <span>订单</span>
      </van-tabbar-item>
      <van-tabbar-item @click="goPage('/my')">
        <template #icon="props">
          <svg-icon
            :icon-class="props.active ? 'smell_select' : 'smell'"
          ></svg-icon>
        </template>
        <span>我的</span>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ifIOS: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    goPage(value) {
      if (value == "/order") {
        if (this.getwxcode("/order")) {
          this.$router.push({
            path: value
          });
        } else {
          switch (window.location.hash) {
            case "#/":
              this.active = 0;
              break;
            case "#/service":
              this.active = 1;
              break;
            case "#/my":
              this.active = 3;
              break;
            default:
              break;
          }
        }
      } else {
        this.$router.push({
          path: value
        });
      }
    }
  },
  created() {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
      this.ifIOS = false;
    }
    if (isiOS) {
      this.ifIOS = true;
    }
  },
};
</script>
<style lang="less" scoped></style>
