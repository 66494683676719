import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    phoneLoginShow: false,
    empowerShow: false,
    loadingShow: false,
    tabBarActive: 0,
  },
  mutations: {
    phoneLoginControl(state, value) {
      state.phoneLoginShow = value;
    },
    empowerControl(state, value) {
      state.empowerShow = value;
    },
    loadingControl(state, value) {
      state.loadingShow = value;
    },
    tabBarActiveControl(state, value) {
      state.tabBarActive = value;
    },
  },

  actions: {}
});
