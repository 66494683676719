<template>
  <div id="app">
    <keep-alive>
      <router-view
        v-wechat-title="$route.meta.title"
        v-if="$route.meta.keepAlive"
      />
    </keep-alive>
    <router-view
      v-wechat-title="$route.meta.title"
      v-if="!$route.meta.keepAlive"
    />
    <phoneLogin v-if="$store.state.phoneLoginShow" />
    <wxeEmpower v-if="$store.state.empowerShow" />
    <loading v-if="$store.state.loadingShow" />
    <footBar  v-if="footBarShow"/>
  </div>
</template>
<script>
import phoneLogin from "@/components/phoneLogin";
import wxeEmpower from "@/components/wxeEmpower";
import loading from "@/components/loading";
import footBar from "@/components/footBar";
import { addWxUserDetail } from "@/api/index";
export default {
  components: { phoneLogin, loading, wxeEmpower,footBar },
  data() {
    return {
      footBarActive:0,
      footBarShow:true
    }
  },
  created() {
    let paramsString = window.location.search;
    let searchParams = new URLSearchParams(paramsString);
    let paramValue = searchParams.get("code") || "";

    let shareCode = this.$route.query.shareCode || '';
    if(shareCode){
      localStorage.setItem("shareCode",shareCode)
    }
    
    if (paramValue && !localStorage.getItem("openId")) {
      let data = {
        code: paramValue,
        inviteCode : localStorage.getItem("shareCode")
      }
      addWxUserDetail(data).then(res => {
        localStorage.setItem("isBindMobile", res.data.isBindMobile);
        localStorage.setItem("openId", res.data.openId);
        localStorage.setItem("authorization", res.data.authorization);
        localStorage.removeItem('shareCode')
        this.$store.commit("empowerControl", false);
        if (res.data.isBindMobile == 2) {
          this.$store.commit("phoneLoginControl", true);
        } else {
          this.$router.push({
            path: sessionStorage.getItem("routePath")
          });
          sessionStorage.removeItem("routePath");
        }
      });
    } else if (paramValue && localStorage.getItem("openId")) {
      this.$store.commit("empowerControl", false);
    }
  },
  watch:{
    $route(to,from){
      if(to.path == "/" || to.path == "/service"|| to.path == "/order"|| to.path == "/my"){
        this.footBarShow = true
      }else{
        this.footBarShow = false
      }
      switch (to.path) {
        case '/':
          this.$store.commit('tabBarActiveControl',0)
          break;
        case '/service':
          this.$store.commit('tabBarActiveControl',1)
          break;
        case '/order':
          this.$store.commit('tabBarActiveControl',2)
          break;
        case '/my':
          this.$store.commit('tabBarActiveControl',3)
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style lang="less">
#app {
  min-height: 100vh;
}

body {
  -webkit-touch-callout: none;
  /*系统默认菜单被禁用*/

  -webkit-user-select: none;
  /*webkit浏览器*/

  -khtml-user-select: none;
  /*早起浏览器*/

  -moz-user-select: none;
  /*火狐浏览器*/

  -ms-user-select: none;
  /*IE浏览器*/

  user-select: none;
  /*用户是否能够选中文本*/
}
.nullDataDiv {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .text {
    color: gray;
  }
}
input,
texteare {
  -webkit-user-select: auto;
}
.van-list__finished-text,
.van-list__placeholder {
  font-size: 0.28rem !important;
  line-height: .6rem !important;
}
.van-popup--center.van-popup--round {
  border-radius: 10px !important;
}
.van-popup--bottom.van-popup--round {
  border-radius: 10px 10px 0 0 !important;
}
.dialogClassName {
  border-radius: 0.2rem !important;
  .van-button {
    font-size: 0.32rem;
  }
}
@supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) { 
    #app {
        margin-bottom: constant(safe-area-inset-bottom);
        margin-bottom: env(safe-area-inset-bottom);  
    }
}
</style>
